import { useState } from "react";

const colors = [
  "#36fb40",
  // "#36fb9b",
  "#36fbf6",
  // "#36a5fb",
  "#364afb",
  "#7d36fb",
  // "#d836fb",
  "#fb36c4",
  // "#fb3669",
  "#fb5e36",
  "#fbb936",
];

export const colorAtIndex = (index: number) => {
  return colors[index % colors.length];
};

export const randomColor = () => {
  return colorAtIndex(Math.floor(Math.random() * colors.length));
};

export const useColor = (seed?: number) => {
  const [color, setColor] = useState("");
  const generateColor = (seed?: number) => {
    if (seed) {
      setColor(colorAtIndex(seed));
      // setColor(Math.random().toString(16).substr(-6));
    } else {
      setColor(randomColor());
    }
  };
  generateColor(seed);
  return [color, generateColor];
};
