import { Paragraph } from "../../components/Paragraph";
import { ParagraphHeader } from "../../components/ParagraphHeader";
import { Sticker } from "../../components/Sticker";
import { UnderConstructionBanner } from "../../components/UnderConstructionBanner";
import { FullscreenShader, shader2 } from "../background-clouds/Canvas";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PlasmaPopSegment {
  raeRefHolder?: React.RefObject<HTMLDivElement>;
}

export const PlasmaPopSegment: React.FC<PlasmaPopSegment> = ({
  raeRefHolder,
}) => {
  const scrollToRae = () => {
    if (raeRefHolder && raeRefHolder.current) {
      raeRefHolder.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  return (
    <>
      <div className="overflow-x-hidden absolute z-10 w-screen h-screen pointer-events-none">
        <div className="sticky -z-20">
          <UnderConstructionBanner
            className="-top-20 -left-24 -ml-2 sm:-top-28"
            style={{
              transform: "rotate(-3deg)",
            }}
          />
          <UnderConstructionBanner
            className="-top-8 -ml-3 sm:-top-24 -z-20"
            style={{
              transform: "rotate(5deg)",
            }}
          />
        </div>
      </div>
      <div className="absolute w-screen h-screen bg-black -z-20">
        <div className="flex justify-center w-screen h-screen t-0">
          <FullscreenShader shader={shader2} />
          {/* <div className="absolute bottom-0 w-screen h-6 bg-gradient-to-t from-black"></div> */}
        </div>
      </div>

      <div className="flex relative justify-center mt-24 -mb-24 h-96 sm:mt-0 min-h-96 sm:h-5/6">
        <div className="flex relative justify-center items-center m-auto h-full">
          <div
            className="relative text-xl text-black text-xxl sm:text-xxxl md:text-xxxxl"
            style={{
              // fontSize: "10rem",
              lineHeight: "0.8",
              fontWeight: 900,
              textShadow: "0 0 20px white, 0 0 8px white, 0 0 30px black",
            }}
          >
            <p>The Ringtones</p>
            <Sticker
              width="11em"
              className="absolute -left-12 -top-56 text-base rounded-full sm:-top-40"
              classNameParagraph="flex flex-col text-3xl sm:text-2xl"
              style={{
                background: "#fb5e36",
                transform: "rotate(-12deg)",
              }}
            >
              <span className="pt-2.5 m-auto">WELCOME</span>
              <span className="m-auto">TO THE</span>
              <span className="m-auto">HOMEPAGE</span>
              <span className="m-auto">OF</span>
              <Sticker
                width="4rem"
                style={{ background: "#fb36c4" }}
                className="absolute -top-4 -left-4 -z-20"
              />
              <Sticker
                width="8rem"
                style={{ background: "#d836fb" }}
                className="absolute top-10 -right-20 -z-20"
              />
              <Sticker
                width="5rem"
                style={{ background: "#364afb" }}
                className="absolute top-20 -left-24"
              />
            </Sticker>
          </div>
        </div>
      </div>
      <UnderConstructionBanner
        className="sticky -top-12 z-10 -ml-2"
        style={{
          transform: "rotate(2deg)",
        }}
      />
      <Paragraph
        className="mb-56 md:mb-12"
        header="Ring tones is an electroacoustic pop band from Finland"
      >
        <div className="px-4 py-6 md:px-12">
          <Sticker
            ContainerProps={{ onClick: scrollToRae, role: "button" }}
            width="10rem"
            className="-bottom-56 left-2/3 md:-right-10 md:top-24 md:-left-40"
            classNameParagraph="m-auto text-xl font-extrabold flex flex-col text-center "
            style={{
              background: "#d836fb",
              transform: "rotate(-8deg)",
            }}
          >
            <span className="m-auto">NEW SONG!</span>
            <span style={{ fontSize: "2rem" }} className="m-auto text-white">
              RAÉ
            </span>
            <span className="m-auto">OUT NOW</span>
            <Sticker width="4rem" className="absolute -right-4 -bottom-4" />
            <Sticker width="2.5rem" className="absolute right-4 -top-10" />
            <Sticker width="2rem" className="absolute right-1 -top-12" />
            <Sticker
              width="5rem"
              className="hidden absolute -left-16 top-20 sm:block"
            />
          </Sticker>{" "}
          Introducing Ring Tones—the hottest new electroacoustic pop band from
          Finland! With their signature sound, Ring Tones is sure to be the
          biggest sound of today. Drawing from the best of traditional and
          modern pop, Ring Tones creates a unique blend of music that will keep
          you dancing all night long. Their catchy melodies, groovy beats, and
          smooth harmonies will take you on a journey through the ages, from
          classic sounds of the 50s to the cutting edge sounds of today. So if
          you're looking for a truly original and unforgettable sound, look no
          further than Ring Tones!
        </div>
      </Paragraph>
      <Paragraph header="Pleasing and sentimental background music">
        <Sticker
          width="9rem"
          className="-bottom-36 right-2/3 md:-right-24 -z-10"
          classNameParagraph="flex flex-col m-auto text-xl font-extrabold"
          style={{
            background: "#fb3669",
            transform: "rotate(8deg)",
          }}
          shadowSide="right"
        >
          <span className="m-auto">UPDATED</span>
          <span className="m-auto">OFTEN</span>
          <Sticker
            width="4rem"
            className="absolute -right-4 -bottom-4 -z-20"
            shadowSide="right"
          />
          <Sticker
            width="4rem"
            className="absolute right-1 -top-10 -z-20"
            shadowSide="right"
          />
          <Sticker
            width="5rem"
            className="absolute top-20 -left-20"
            shadowSide="right"
          />
        </Sticker>
        <p className="px-4 py-6 md:px-12">
          Do you miss some sweet and sentimental sounds to your life? If you do,
          Ring Tones makes music for you! These talented musicians have crafted
          a collection of beautiful songs that will transport you back to a
          simpler time. Their functional and soothing music is sure to bring a
          smile to your face. So take a break from the modern day and indulge in
          the soothing sound of Ring Tones. With gentle, heartfelt melodies and
          kind harmonies, their songs will make your phone and life ring with
          joy!
        </p>
      </Paragraph>
      <Paragraph header="Only a few songs so far" className="mt-56">
        <p className="px-4 py-6 md:px-12">
          If you’re looking for a unique and exciting sounds, to soothen your
          life, then you’ve found it. With only a few songs released so far,
          this band is already making waves and setting the stage for a long and
          successful career. Their music combines classic pop and modern
          alternative styles, giving them a fresh and unique sound that is sure
          to captivate and excite. Whether you’re a fan of pop, chill-out,
          alternative, electronica or just great music in general, Ring Tones
          the band can provide it. With a few more songs coming soon, the game
          is on for Ring Tones, and it’s one you won’t want to miss!
        </p>
      </Paragraph>

      <UnderConstructionBanner
        className="sticky -top-24 z-40 mt-16"
        style={{
          transform: "rotate(-2deg)",
        }}
      />
    </>
  );
};
