// eslint-disable-next-line @typescript-eslint/no-empty-interface
import texture1 from "./img/rae-texture1.jpg";
import texture2 from "./img/rae-texture2.jpg";
import raeCover from "./img/Rae-cover-full.jpg";

export interface RaeSegment {}

export const RaeSegment: React.FC<RaeSegment> = ({}) => {
  return (
    <div className="py-16 bg-black sm:py-32 sm:py-24">
      <div>
        <div className="p-2 sm:p-12 md:mx-24">
          <div
            className="relative w-full h-full font-serif aspect-square"
            style={{
              background: `url(${raeCover})`,
              backgroundSize: "cover",
              textShadow:
                "0 0 1px white, 0 0 2px white, 0 0 3px white, 0 0 5px white, 0 0 10px white, 0 0 20px white, 0 0 30px white, 0 0 10px white",
            }}
          >
            <div className="absolute w-full font-extrabold text-center top-[8%] text-xxl">
              The Ringtones
            </div>
            <div className="absolute w-full font-extrabold text-center bottom-[8%] text-xxl">
              Raé
            </div>
          </div>
        </div>
        <div className="p-2 mx-2 my-2 sm:mx-12 md:mx-36 sm:my-2 bg-slate-300">
          <audio
            className="w-full rounded-none playing:animate-pulse"
            controls
            src="https://ring-tones.fra1.cdn.digitaloceanspaces.com/releases/ring_tones_-_rae.mp3"
          />
        </div>
        <div className="p-2 sm:p-12 md:mx-24">
          <div
            className="text-sm text-white border-rae-s sm:font-bold sm:text-xl sm:border-rae-l p-rae-s sm:p-rae-l"
            style={{
              borderImage: `url(${texture1}) 2 2 2 2 stretch stretch`,
              background: `url(${texture2})`,
            }}
          >
            <h1
              className="m-3 text-5xl font-extrabold text-center xl:text-9xl sm:m-8 text-slate-300 sm:text-6xl md:text-7xl lg:text-8xl font-rae"
              style={{
                textShadow:
                  "0 0 1px white, 0 0 2px white, 0 0 3px white, 0 0 5px white, 0 0 10px white, 0 0 20px white, 0 0 30px white, 0 0 10px white",
              }}
            >
              <span className="text-slate-400">Raé</span> is the first released
              song by <span className="text-slate-400">Ring Tones</span>
            </h1>
            <p className="mt-5 sm:mt-12 py-5 pl-2 bg-[#808080aa] max-w-prose mx-auto">
              The band had it's start when two friends found out they have a
              shared passion for music. They come from a different yet similar
              musical background. They started jamming together and were soon
              writing and rehearsing their own original music.
            </p>
            <p className="mt-5 sm:mt-12 py-5 pl-2 bg-[#808080aa] max-w-prose mx-auto">
              Combining electonic tools and acoustic instruments, they created a
              distinct sound that is both familiar and fresh. The song Raé
              brings out the voice of the acoustic guitar. The song has that
              certain kind of a soundscape - Both nostalgicically familiar and
              refreshingly new.
            </p>
            <p className="mt-5 sm:mt-12 py-5 pl-2 bg-[#808080aa] max-w-prose mx-auto">
              The song "Raé" by Ring Tones is a potent, introspective and
              personal track that reflects on the journey of life. It is built
              on a careful balance of various elements: Chillout
              electro-acoustic beats, lush synths, and other electronic
              production techniques blended together with the warm, natural
              sound of an acoustic guitar.
            </p>
          </div>
        </div>
      </div>
      <div>
        <div
          className="m-2 p-rae-s sm:p-rae-l sm:mx-12 md:mx-36 sm:my-2"
          style={{
            background: `url(${texture2})`,
          }}
        >
          <div
            className="w-full text-xl font-rae sm:p-12 sm:text-6xl sm:text-center sm:text-[#fb5e36] border-rae-s sm:border-rae-l"
            style={{
              textShadow:
                "0 0 1px white, 0 0 2px white, 0 0 3px white, 0 0 5px white, 0 0 10px white, 0 0 20px white, 0 0 30px white, 0 0 10px white",
              borderImage: `url(${texture1}) 2 2 2 2 stretch stretch`,
              background: `url(${texture1})`,
              letterSpacing: "-0.05em",
            }}
          >
            <div className="flex flex-wrap justify-around items-center text-center">
              <span className="p-4">adventure</span>
              <span className="p-4">new beginnings </span>
              <span className="p-4">exploration</span>
              <span className="p-4">self-improvement</span>
              <span className="p-4">breaking out of comfort zones</span>
              <span className="p-4">discovering new things</span>
              <span className="p-4">the journey of life</span>
              <span className="p-4">growing</span>
              <span className="p-4">curiosity</span>
              <span className="p-4">self-discovery</span>
              <span className="p-4">learning</span>
              <span className="p-4">the world around</span>
              <span className="p-4">growth</span>
              <span className="p-4">developing</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
