// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UnderConstructionBanner {
  className?: string;
  style?: React.CSSProperties;
}

export const UnderConstructionBanner: React.FC<UnderConstructionBanner> = ({
  className,
  style,
}) => {
  return (
    <div
      className={`absolute hidden w-screen text-xl tracking-widest sm:block h-28 sm:h-32 sm:text-5xl ${className}`}
      style={{
        background: "#fbb936",
        border: "1rem dashed black",
        borderLeft: 0,
        borderRight: 0,
        display: "flex",
        justifyContent: "center",
        width: "245%",
        left: "-50%",
        transform: "rotate(-3deg)",
        fontWeight: 900,
        overflow: "hidden",
        ...style,
      }}
    >
      <div className="m-auto -ml-32 overflow-hidden">
        <p className="overflow-hidden whitespace-nowrap">
          <span
            style={{ marginLeft: "-200px" }}
            className="animate-roll-text-slow"
          >
            &nbsp;
          </span>
          SITE UNDER CONSTRUCTION - SITE UNDER CONSTRUCTION - SITE UNDER
          CONSTRUCTION - SITE UNDER CONSTRUCTION - SITE UNDER CONSTRUCTION -
          SITE UNDER CONSTRUCTION - SITE UNDER CONSTRUCTION - SITE UNDER
          CONSTRUCTION - SITE UNDER CONSTRUCTION - SITE UNDER CONSTRUCTION -
          SITE UNDER CONSTRUCTION - SITE UNDER CONSTRUCTION - UNDER CONSTRUCTION
          - SITE UNDER CONSTRUCTION - SITE UNDER CONSTRUCTION - SITE UNDER
          CONSTRUCTION - SITE UNDER CONSTRUCTION - SITE UNDER CONSTRUCTION -
          SITE UNDER CONSTRUCTION - SITE UNDER CONSTRUCTION - SITE UNDER
          CONSTRUCTION - SITE UNDER CONSTRUCTION - SITE UNDER CONSTRUCTION -
          SITE UNDER CONSTRUCTION -{" "}
        </p>

        <p className="overflow-hidden whitespace-nowrap">
          <span
            style={{ marginLeft: "200px" }}
            className="animate-roll-text-slow-inverse"
          >
            &nbsp;
          </span>
          SITE UNDER CONSTRUCTION - SITE UNDER CONSTRUCTION - SITE UNDER
          CONSTRUCTION - SITE UNDER CONSTRUCTION - SITE UNDER CONSTRUCTION -
          SITE UNDER CONSTRUCTION - SITE UNDER CONSTRUCTION - SITE UNDER
          CONSTRUCTION - SITE UNDER CONSTRUCTION - SITE UNDER CONSTRUCTION -
          SITE UNDER CONSTRUCTION - SITE UNDER CONSTRUCTION - UNDER CONSTRUCTION
          - SITE UNDER CONSTRUCTION - SITE UNDER CONSTRUCTION - SITE UNDER
          CONSTRUCTION - SITE UNDER CONSTRUCTION - SITE UNDER CONSTRUCTION -
          SITE UNDER CONSTRUCTION - SITE UNDER CONSTRUCTION - SITE UNDER
          CONSTRUCTION - SITE UNDER CONSTRUCTION - SITE UNDER CONSTRUCTION -
          SITE UNDER CONSTRUCTION -{" "}
        </p>

        {/* <p className="overflow-hidden whitespace-nowrap">
          <span
            style={{ marginLeft: "-200px" }}
            className="animate-roll-text-slow"
          >
            &nbsp;
          </span>
          SITE UNDER CONSTRUCTION - SITE UNDER CONSTRUCTION - SITE UNDER
          CONSTRUCTION - SITE UNDER CONSTRUCTION - SITE UNDER CONSTRUCTION -
          SITE UNDER CONSTRUCTION - SITE UNDER CONSTRUCTION - SITE UNDER
          CONSTRUCTION - SITE UNDER CONSTRUCTION - SITE UNDER CONSTRUCTION -
          SITE UNDER CONSTRUCTION - SITE UNDER CONSTRUCTION - UNDER CONSTRUCTION
          - SITE UNDER CONSTRUCTION - SITE UNDER CONSTRUCTION - SITE UNDER
          CONSTRUCTION - SITE UNDER CONSTRUCTION - SITE UNDER CONSTRUCTION -
          SITE UNDER CONSTRUCTION - SITE UNDER CONSTRUCTION - SITE UNDER
          CONSTRUCTION - SITE UNDER CONSTRUCTION - SITE UNDER CONSTRUCTION -
          SITE UNDER CONSTRUCTION -{" "}
        </p> */}
      </div>
    </div>
  );
};
