import { ParagraphHeader } from "./ParagraphHeader";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Paragraph {
  header?: string;
  children: React.ReactNode;
  className?: string;
}

export const Paragraph: React.FC<Paragraph> = ({
  header,
  children,
  className = "",
}) => {
  return (
    <div
      className={`mx-3 bg-gradient-to-b from-gray-300 relative sm:mx-auto mt-24 bg-white max-w-prose rounded-xl ${className}`}
    >
      {header && <ParagraphHeader className="-top-4">{header}</ParagraphHeader>}
      {children}
    </div>
  );
};
