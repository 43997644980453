import { useMemo } from "react";
import { randomColor, useColor } from "../hooks/useColor";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Sticker {
  width?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  styleParagraph?: React.CSSProperties;
  className?: string;
  classNameParagraph?: string;
  shadowSide?: "left" | "right";
  ContainerProps?: React.HTMLAttributes<HTMLDivElement>;
}

// ClassNames required by tailwind
export const Sticker: React.FC<Sticker> = ({
  width = "6rem",
  children,
  style,
  className,
  styleParagraph,
  classNameParagraph,
  shadowSide = "left",
  ContainerProps = {},
}) => {
  const color = useMemo(() => {
    return randomColor();
  }, []);

  const background = style?.background ?? color;
  return (
    <div
      className={`absolute flex rounded-full ${className}`}
      style={{
        width,
        height: width,
        background,
        boxShadow:
          shadowSide === "left"
            ? "1px 1px 2px #ffffff55 inset, 2px 2px 1px #00000044"
            : "1px 1px 2px #ffffff55 inset, -2px 2px 1px #00000044",
        ...style,
      }}
      {...ContainerProps}
    >
      {children && (
        <div
          className="absolute w-full h-full rounded-full -z-10"
          style={{
            background,
            boxShadow:
              shadowSide === "left"
                ? "1px 1px 2px #ffffff55 inset, 2px 2px 1px #00000044"
                : "1px 1px 2px #ffffff55 inset, -2px 2px 1px #00000044",
          }}
        />
      )}
      <div
        className={`m-auto text-xl font-extrabold ${classNameParagraph}`}
        style={{
          ...styleParagraph,
        }}
      >
        {children}
        {/* <Sticker
          className={`z-0 ${className?.split(/\s*?z-\d+/).join("")}`}
          style={style}
        /> */}
      </div>
    </div>
  );
};
