import { FC, useEffect, useRef, useState } from "react";
import glslCanvas from "glslCanvas";

export interface FullscreenShader {
  shader: string;
}

export const shader = `
#ifdef GL_ES
  precision mediump float;
#endif


uniform float u_time;
uniform vec2 u_resolution;


vec3 palette(float d){
	return mix(vec3(0.2,0.7,0.9),vec3(1.,0.,1.),d);
}

vec2 rotate(vec2 p,float a){
	float c = cos(a);
    float s = sin(a);
    return p*mat2(c,s,-s,c);
}

float map(vec3 p){
    for( int i = 0; i<8; ++i){
        float t = u_time*0.2;
        p.xz =rotate(p.xz,t);
        p.xy =rotate(p.xy,t*1.89);
        p.xz = abs(p.xz);
        p.xz-=.5;
	}
	return dot(sign(p),p)/5.;
}

vec4 rm (vec3 ro, vec3 rd){
    float t = 0.;
    vec3 col = vec3(0.);
    float d;
    for(float i =0.; i<64.; i++){
		vec3 p = ro + rd*t;
        d = map(p)*.5;
        if(d<0.02){
            break;
        }
        if(d>100.){
        	break;
        }
        //col+=vec3(0.6,0.8,0.8)/(400.*(d));
        col+=palette(length(p)*.1)/(400.*(d));
        t+=d;
    }
    return vec4(col,1./(d*100.));
}
void mainImage( out vec4 fragColor, in vec2 fragCoord )
{
    vec2 uv = (fragCoord-(u_resolution.xy/2.))/u_resolution.x;
	vec3 ro = vec3(0.,0.,-50.);
    ro.xz = rotate(ro.xz,u_time);
    vec3 cf = normalize(-ro);
    vec3 cs = normalize(cross(cf,vec3(0.,1.,0.)));
    vec3 cu = normalize(cross(cf,cs));
    
    vec3 uuv = ro+cf*3. + uv.x*cs + uv.y*cu;
    
    vec3 rd = normalize(uuv-ro);
    
    vec4 col = rm(ro,rd);
    
    
    fragColor = col;
}
void main() {
  mainImage(gl_FragColor, gl_FragCoord.xy);
}
`;
export const shader2 = `
#ifdef GL_ES
  precision highp float;
#endif


uniform float u_time;
uniform vec2 u_resolution;

void main() {
  float time = u_time * 50.0;
  float slowTime =  u_time / 100000.0;
  float loopTime = u_time / 2.0;

  float scale = 0.03 * slowTime + 0.003 * 10.0 * 0.125;
  float r1 = 0.1;
  float r2 = 0.5;
  float r3 = 0.4;
  float x = gl_FragCoord.x;
  float y = gl_FragCoord.y;
  float h = u_resolution.y;
  float w = u_resolution.x;

  float col = sin(distance(vec2(x * r1 + time, y * r2), vec2(w / r3 , h) ) * scale) + sin(distance( vec2(x, y * r2), vec2(1.0 / h * r3, w * r1) ) * scale) + sin(distance( vec2(r3 * x + time, r1 * y + time), vec2(w * r2 + h * r1, h * r2) ) * scale) + sin(distance( vec2(1.0 / x * r3 , y * r2), vec2(h, w) ) * scale);

  vec3 color = vec3(cos(col) - sin(col) - 0.4, sin(col),  0.5 + 0.5 * sin(col)) + 0.1;
  color += mod(gl_FragCoord.x, 2.0) < 1.0 ? 0.0 : 0.6;

  gl_FragColor = vec4(color,  1.0);
}`;

export const createShader = (props = { timeSync: false }) => `
  #ifdef GL_ES
  precision mediump float;
  #endif

  uniform float u_time;
  uniform vec2 u_resolution;
  
  float expStep( float x, float k, float n ){
    return (-0.5 + sin(u_time * 0.2)) /  exp( -k*pow(x,n) );
  }
  
  void main() {
    vec2 point = gl_FragCoord.xy / u_resolution.xy;
    float px = 1.0 / u_resolution.y;
    vec2 cp = vec2(cos(u_time),sin(u_time)) * 0.618 + 0.620;
  
    float l = expStep(point.x, ${props.timeSync ? "cp.x * u_time" : "cp.x"}, ${
  props.timeSync ? "cp.y * u_time" : "cp.y"
});
    
    vec3 color = vec3(smoothstep(l, l+px, point.y), sin(u_time) * 0.1, cos(cp.y) * 0.5);
      
    gl_FragColor = vec4(color, 1.0);
  }
`;

// A fullscreen component to render shaders
export const FullscreenShader: FC<FullscreenShader> = ({ shader }) => {
  const [sandbox, setSandbox] = useState<typeof glslCanvas>();
  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
  }>({ width: 90, height: 160 });
  const ref = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (ref) {
      const sandbox = new glslCanvas(ref.current);
      setSandbox(sandbox);
    }
  }, [shader, ref]);

  useEffect(() => {
    if (sandbox) {
      sandbox.load(shader);
    }
  }, [shader, sandbox]);

  useEffect(() => {
    const resizeHandler = () => {
      if (window.visualViewport) {
        const { width, height } = window?.visualViewport;
        if (width !== dimensions.width) {
          setDimensions({ width, height });
        }
      }
    };
    if (window) {
      window.addEventListener("resize", resizeHandler);
      resizeHandler();
    }
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [dimensions.width]);

  return (
    <canvas ref={ref} width={dimensions.width} height={dimensions.height} />
  );
};
