import { ParagraphHeader } from "../../components/ParagraphHeader";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CheckBackSegment {}

export const CheckBackSegment: React.FC<CheckBackSegment> = ({}) => {
  return (
    <div className="relative mx-auto mt-24 text-center bg-white max-w-prose rounded-xl">
      <ParagraphHeader className="-top-4">
        Check back soon for new content!
      </ParagraphHeader>
    </div>
  );
};
