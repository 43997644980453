import { useRef } from "react";
import "./App.css";
import { UnderConstructionBanner } from "./components/UnderConstructionBanner";
import { MidiDownloadSection } from "./features/midi-download/MidiDownloadSection";
import { CheckBackSegment } from "./features/plasma-pop/CheckBackSegment";
import { PlasmaPopSegment } from "./features/plasma-pop/PlasmaPopSegment";
import { RaeSegment } from "./features/rae/RaeSegment";

function App() {
  const raeRef = useRef<HTMLDivElement>(null);
  return (
    <div
      className="h-screen overflow-x-hidden overflow-y-scroll"
      style={{
        perspectiveOrigin: "bottom",
      }}
    >
      <PlasmaPopSegment raeRefHolder={raeRef} />
      <div ref={raeRef}>
        <RaeSegment />
      </div>

      <UnderConstructionBanner
        className="sticky z-50 -mt-24 -ml-4 sm:-top-8 -top-10"
        style={{
          transform: "rotate(1deg) perspective(500px)",
        }}
      />
      <div className="z-0 -mt-24 bg-black">
        <MidiDownloadSection />
      </div>
      <div className="relative pt-16 mx-12">
        <UnderConstructionBanner
          className="-ml-2 -top-24 -left-24"
          style={{
            transform: "rotate(1deg)",
          }}
        />
        <CheckBackSegment />
      </div>
      <div className="relative mx-auto mt-24 text-center bg-white max-w-screen">
        <div>All rights reserved - 2022-2023</div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div id="gc"></div>
        </div>
      </div>
    </div>
  );
}

export default App;
