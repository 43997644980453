// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ParagraphHeader {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

export const ParagraphHeader: React.FC<ParagraphHeader> = ({
  children,
  style,
  className,
}) => {
  return (
    <p
      className={`relative -top-3 ${className}`}
      style={{
        fontSize: "2rem",
        fontWeight: 700,
        lineHeight: "0.8",
        textShadow: "0 0 4px white, 0 0 12px white, 0 0 40px black",
        letterSpacing: "-0.2rem",
        ...style,
      }}
    >
      {children}
    </p>
  );
};
