// eslint-disable-next-line @typescript-eslint/no-empty-interface
import flame21 from "./img/flame2-1.gif";
import flame22 from "./img/flame2-2.gif";
import flame23 from "./img/flame2-3.gif";
import flame24 from "./img/flame2-4.gif";
import flame25 from "./img/flame2-5.gif";
import flame26 from "./img/flame2-6-slow.gif";
import flame3 from "./img/flame3.gif";
import flame31 from "./img/flame3-1.gif";
import flame32 from "./img/flame3-2.gif";
import flame33 from "./img/flame3-3.gif";
import flame34 from "./img/flame3-4.gif";
import flame35 from "./img/flame3-5.gif";
import flame36 from "./img/flame3-6.gif";
import { useState, useEffect } from "react";

export interface MidiDownloadSection {}

const images = [flame23, flame21, flame22, flame25, flame24];

const headerFlame = [flame31, flame32, flame33, flame34, flame35, flame36];
const randomFlameGif = () => {
  return headerFlame[Math.floor(Math.random() * headerFlame.length)];
};
export const MidiDownloadSection: React.FC<MidiDownloadSection> = ({}) => {
  const [flameGif, setFlameGif] = useState(randomFlameGif());

  useEffect(() => {
    const timer = setTimeout(() => {
      setFlameGif(randomFlameGif());
    }, 5000);
    return () => clearTimeout(timer);
  }, [flameGif, setFlameGif]);

  return (
    <div className="relative z-0 bg-black">
      <div className="absolute z-10 flex w-full h-full">
        <div className="flex flex-col justify-center w-1/2 h-full pt-16 m-auto font-serif text-center text-white bg-black">
          <div
            className="w-full h-12 mb-6 bg-black bg-contain"
            style={{ backgroundImage: `url(${flame26})` }}
          ></div>
          <div
            className="h-12 mx-auto bg-contain sm:h-36 aspect-square"
            style={{ backgroundImage: `url(${flame3})` }}
          ></div>
          <div className="flex items-center content-between mx-auto sm:text-xl">
            <div
              className="w-12 h-12 bg-center bg-no-repeat bg-contain cursor-pointer"
              style={{ backgroundImage: `url(${flameGif})` }}
              onClick={() => setFlameGif(randomFlameGif())}
            ></div>
            <div>
              <span>MIDI DOWNLOAD SECTION</span>
              <div>
                <p>Check back soon</p>
              </div>
            </div>
            <div
              className="w-12 h-12 bg-center bg-no-repeat bg-contain cursor-pointer"
              style={{ backgroundImage: `url(${flameGif})` }}
              onClick={() => setFlameGif(randomFlameGif())}
            ></div>
          </div>

          <div
            className="h-12 mx-auto bg-contain sm:h-36 aspect-square"
            style={{ backgroundImage: `url(${flame3})` }}
          ></div>
          <div
            className="h-12 bg-contain"
            style={{ backgroundImage: `url(${flame26})` }}
          ></div>
        </div>
      </div>
      <div className="h-full opacity-20">
        <>
          {images.map((image) => (
            <div
              key={image}
              className="bg-black h-36"
              style={{
                background: "url(" + image + ")",
              }}
            ></div>
          ))}
        </>
      </div>
    </div>
  );
};
